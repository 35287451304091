import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/home/HomeView'
import LoginView from '@/views/login/LoginView.vue'
import LeftSideBar from '@/components/home/LeftSideBar.vue'
import HomePageContent from "@/components/home/content/HomePageContent.vue";
import DeviceContent from '@/components/home/content/DeviceContent'
import UserContent from "@/components/home/content/UserContent";
import GateWayContent from "@/components/home/content/GateWayContent";
import FamilyContent from "@/components/home/content/FamilyContent.vue";
import LogContent from "@/components/home/content/LogContent.vue";
import RoomAdd from "@/components/home/dialog/RoomAdd.vue";
import TestMqtt from "@/components/home/content/TestMqtt.vue";
import TestDevice from "@/components/home/content/DeviceCard.vue";

const routes = [
    // 默认路由
  {
    path: '/',
    name: 'index',
    components: {
      main_view: LoginView
    }
  },
    // 主页路由
  {
    path: '/home',
    name: 'home',
    components: {
      main_view: HomeView
    },
    children: [
        // 主页默认路由
      {
        path: 'homepage',
        components: {
          left_sidebar: LeftSideBar,
          home_content: HomePageContent
        }
      },
        // 主页设备管理路由
      {
        path: 'device',
        children: [
            // 设备管理终端管理路由
          {
            path: 'terminal',
            components: {
              left_sidebar: LeftSideBar,
              home_content: DeviceContent
            }
          },
            // 设备管理网关管理路由
          {
            path: 'gateway',
            components: {
              left_sidebar: LeftSideBar,
              home_content: GateWayContent
            }
          },
        ]
      },
        // 日志路由
      {
        path: 'log',
        components: {
          left_sidebar: LeftSideBar,
          home_content: LogContent
        }
      },
        // 家庭管理路由
      {
        path: 'family',
        components: {
          left_sidebar: LeftSideBar,
          home_content: FamilyContent
        }
      },
        // 主页用户管理路由
      {
        path: 'user',
        components: {
          left_sidebar: LeftSideBar,
          home_content: UserContent
        }
      },
        //Mqtt测试
      {
        path: 'testMqtt',
        components: {
          left_sidebar:LeftSideBar,
          home_content:TestMqtt
        }
      },
      //设备卡片页面
      {
        path: 'DevCard',
        components: {
          left_sidebar:LeftSideBar,
          home_content:TestDevice
        }
      },

    ]
  },
  {
    path: '/test',
    name: 'test',
    components: {
      main_view: RoomAdd
    },
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
