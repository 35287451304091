import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
// 引入 element-plus 插件
import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'

const app = createApp(App)

app.use(store).use(router)
app.use(ElementPlus) // 使用 element-plus 插件
app.mount('#app')
