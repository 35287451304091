<template>
  <el-container class="container_family">
    <el-header class="header_family">
      <div class="wrapper_family_header_head">
        <span class="text_family_header_head">家庭管理</span>
      </div>
      <div class="wrapper_family_header_comps">
        <el-button class="btn_family_refresh"
                   type="primary"
                   @click="refresh">刷新</el-button>
      </div>
    </el-header>
    <el-main class="main_family">
      <div class="wrapper_family_main">
        <div class="wrapper_family_main_head">
          <span class="text_family_main_head">房间列表</span>
          <el-button class="btn_family_add"
                     :icon="Plus"
                     @click="addRoom">添加</el-button>
        </div>
        <div class="wrapper_family_main_table">
          <el-table :data="tableData" stripe>
            <el-table-column prop="roomID" label="房屋ID"/>
            <el-table-column prop="roomName" label="房间名称"/>
            <el-table-column prop="roomUser" label="房间用户"/>
            <el-table-column prop="roomFloor" label="所在楼层" />
            <el-table-column prop="roomArea" label="占地面积（㎡）" />
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                    size="small"
                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button
                    size="small"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-main>

    <el-dialog
        v-model="addDialogVisible"
        title="新增房间"
        width="30%"
    >
      <template #default>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入房间名称</span>
            <el-input v-model="room_name_add"/>
          </el-col>
          <el-col :span="12">
            <span>请输入用户名称</span>
            <el-input v-model="room_user_add"/>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入所在楼层</span>
            <el-input v-model="room_floor_add"/>
          </el-col>
          <el-col :span="12">
            <span>请输入占地面积㎡</span>
            <el-input v-model="room_area_add"/>
          </el-col>
        </el-row >
        <el-row justify="center" style="margin-top: 60px">
          <el-button type="primary" @click="add_confirm">确定</el-button>
          <el-button @click="add_cancel">取消</el-button>
        </el-row>
      </template>
    </el-dialog>
    <el-dialog
        v-model="updateDialogVisible"
        title="修改"
        width="30%"
    >
      <template #default>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入房间名称</span>
            <el-input v-model="room_name_edit"/>
          </el-col>
          <el-col :span="12">
            <span>请输入用户名称</span>
            <el-input v-model="room_user_edit"/>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入所在楼层</span>
            <el-input v-model="room_floor_edit"/>
          </el-col>
          <el-col :span="12">
            <span>请输入占地面积㎡</span>
            <el-input v-model="room_area_edit"/>
          </el-col>
        </el-row >
        <el-row justify="center" style="margin-top: 60px">
          <el-button type="primary" @click="edit_confirm">确定</el-button>
          <el-button @click="edit_cancel">取消</el-button>
        </el-row>
      </template>
    </el-dialog>
  </el-container>

</template>

<script >
import { Plus } from "@element-plus/icons-vue";
import axios from "axios";
import { myAlert } from '@/util/msg'

export default {
  name: 'FamilyContent',
  data() {
    return {
      addDialogVisible: false,
      updateDialogVisible:false,
      Plus,
      tableData: '',
      room_id_add: '',
      room_name_add: '',
      room_floor_add: 0,
      room_area_add: 0,
      room_user_add: '',
      room_id_edit: '',
      room_name_edit: '',
      room_floor_edit: 0,
      room_area_edit: 0,
      room_user_edit:'',
      roomID:''

    }
  },
  methods: {
    /**
     * 编辑操作
     * @param index 行索引
     * @param row 行数据
     */
    handleEdit(index, row) {
      this.updateDialogVisible = true
      this.room_name_edit = row.roomName
      this.room_floor_edit = row.roomFloor
      this.room_area_edit = row.roomArea
      this.room_user_edit = row.roomUser
      this.roomID = row.roomID
    },
    edit_confirm(){
      let data = {
        'roomID': this.roomID,
        'roomName': this.room_name_edit,
        'roomFloor': this.room_floor_edit,
        'roomArea': this.room_area_edit,
        'roomUser':this.room_user_edit
      }
      // 发送 post 请求，url：'/zqhome/updateRoomInfo'
      axios({
        url: '/zqhome/updateRoomInfo',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否修改成功
        if (result.Code === 440) {
          myAlert(this.$message, result.Message, 'success')
          this.refresh()
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
      this.updateDialogVisible = false
    },
    edit_cancel(){
      this.updateDialogVisible = false
    },
    /**
     * 删除操作
     * @param index 行索引
     * @param row 行数据
     */
    handleDelete(index, row) {
      console.log(index, row)
      let data = {
        'roomID': row.roomID
      }
      // 发送 post 请求，url：'/zqhome/deleteRoom'
      axios({
        url: '/zqhome/deleteRoom',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否删除成功
        if (result.Code === 424) {
          myAlert(this.$message, result.Message, 'success')
          this.refresh()
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    },
    /**
     * 删除按钮
     */
    addRoom() {
      this.addDialogVisible = true
    },
    /**
     * 添加房间按钮
     */
    add_confirm() {
      let data = {
        'roomName': this.room_name_add,
        'roomUser': this.room_user_add,
        'roomFloor': this.room_floor_add,
        'roomArea': this.room_area_add
      }
      // 发送 post 请求，url：'/zqhome/insertRoom'
      axios({
        url: '/zqhome/insertRoom',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否添加成功
        if (result.Code === 427) {
          myAlert(this.$message, result.Message, 'success')
          this.refresh()
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
      this.addDialogVisible = false
    },
    /**
     * 添加设备对话框确定按钮
     */
    add_cancel() {
      this.addDialogVisible = false
    },
    /**
     * 添加设备对话框取消按钮
     */
    refresh() {
      this.getRoomAll()
    },
    /**
     * 刷新按钮
     */
    getRoomAll() {
      // 发送 post 请求，url：'/zqhome/queryRoomAll'
      axios({
        url: '/zqhome/queryRoomAll',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 421) {
          myAlert(this.$message, result.Message, 'success')
          this.tableData = result.Data
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    }
  },
  mounted() {
    this.getRoomAll()
  }
}
</script>

<style scoped>

.container_family {
  height: 100%;
}

.container_family .header_family {
  height: 150px;
}

.container_family .main_family {
  background-color: lightgrey;
}

.wrapper_family_header_head {
  padding: 10px;
}

.wrapper_family_header_comps {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: right;
}

.text_family_header_head {
  font-size: 30px;
}

.wrapper_family_main {
  background-color: white;
  min-height: 100%;
  min-width: 100%;
  display: table;
}

.wrapper_family_main_head {
  padding: 10px;
  margin-top: 10px;
  color: cornflowerblue;
  display: flex;
  justify-content: space-between;
}

.text_family_main_head {
  font-size: 20px;
}

.btn_family_refresh {
  margin-right: 10px;
}

.btn_family_add {
  margin-right: 10px;
}

.wrapper_family_main_table {
  padding: 10px;
}

</style>
