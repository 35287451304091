<template>
  <el-container class="container_log">
    <el-header class="header_log">
      <div class="wrapper_log_header_head">
        <span class="text_log_header_head">系统日志</span>
      </div>
      <div class="wrapper_log_header_comps">
        <el-select v-model="value" class="select_device_manage_type" placeholder="日志类型" >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"/>
        </el-select>
        <div style="display: flex">
          <span class="log_header_comps_text">周期:</span>
          <el-button>全部</el-button>
          <el-button>今天</el-button>
          <el-button>近三天</el-button>
          <el-button>近一个月</el-button>
          <el-button>近三个月</el-button>
        </div>
        <el-button class="btn_log_refresh"
                   type="primary">刷新</el-button>
      </div>
    </el-header>
    <el-main class="main_log">
      <div class="wrapper_log_main">
        <div class="wrapper_log_main_head">
          <span class="text_log_main_head">日志列表</span>
        </div>
        <div class="wrapper_log_main_table">
          <el-table :data="tempData" stripe tooltip-effect="light">
            <el-table-column prop="gatewayID" label="网关ID"/>
            <el-table-column prop="gatewayStatus" label="网关状态"/>
            <el-table-column prop="connectTime" label="链接时间" />
            <el-table-column prop="disconnectTime" label="断开链接时间"/>
            <el-table-column prop="recordTime" label="记录时间"/>
            <el-table-column prop="gatewayDev" label="日志类型"/>
            <el-table-column prop="gatewayInfo" label="信息" show-overflow-tooltip />
          </el-table>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script >
import { Plus } from "@element-plus/icons-vue";
import axios from "axios";
import { myAlert } from '@/util/msg'
export default {
  name: 'LogContent',
  data() {
    return {
      Plus,
      options,
      value:'',
      tableData:'',
      tempData:''
    }
  },
  mounted() {
    this.getGatewayLogAll()
  },
  watch:{
    value: function(val) {
      if(val == ''){
        this.queryAllDev()
      }else{
        this.tempData=this.tableData.filter((item)=>{
          return item.gatewayType.includes(val)
        })

      }
    },
  },

  methods: {
    getGatewayLogAll(){
      // 发送 post 请求，url：'/zqhome/queryGatewayLogAll'
      axios({
        url: '/zqhome/queryGatewayLogAll',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 481) {
          myAlert(this.$message, result.Message, 'success')
          this.tableData = result.Data
          this.tempData=this.tableData
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    },
    /**
     * 编辑操作
     * @param index 行索引
     * @param row 行数据
     */
    handleEdit(index, row) {
      console.log(index, row)
    },
    /**
     * 删除操作
     * @param index 行索引
     * @param row 行数据
     */
    handleDelete(index, row) {
      console.log(index, row)
    }
  }
}
const options = [
  {
    value:"options_1",
    label:"DEBUG"
  },
  {
    value:"options_2",
    label:"INFO"
  },
  {
    value:"options_3",
    label:"WARNING"
  },
  {
    value:"options_2",
    label:"ERROR"
  },
  {
    value:"",
    label: "全部日志"
  }
]

</script>

<style>

.container_log {
  height: 100%;
}

.container_log .header_log {
  height: 150px;
}

.container_log .main_log {
  background-color: lightgrey;
}

.wrapper_log_header_head {
  padding: 10px;
}

.wrapper_log_header_comps {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.text_log_header_head {
  font-size: 30px;
}

.log_header_comps_text {
  font-size: 20px;
  margin-right: 20px;
}

.wrapper_log_main {
  background-color: white;
  min-height: 100%;
  min-width: 100%;
  display: table;
}

.wrapper_log_main_head {
  padding: 10px;
  margin-top: 10px;
  color: cornflowerblue;
  display: flex;
  justify-content: space-between;
}

.text_log_main_head {
  font-size: 20px;
}

.btn_log_refresh {
  margin-right: 10px;
}

.wrapper_log_main_table {
  padding: 10px;
}

</style>
