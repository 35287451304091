<template>
  <div class="wrapper_login_loginbox">
    <div :style="{boxShadow:'var(--el-box-shadow-dark)'}" class="box_login_space" >
      <el-row>
        <el-col :offset="2" class="row_login_header">
          <el-image :src="logo.url" style="width: auto; height: 100px"></el-image>
        </el-col>
      </el-row>
      <el-row class="row_login_tip">
        <el-col :offset="2">
          <span class="text_login_tip">登录</span>
        </el-col>
      </el-row>
      <el-row class="row_login_account">
        <el-col :span="20" :offset="2">
          <el-input class="input_login_account"
                    @input="change"
                    v-model="account"
                    placeholder="用户账号" />
        </el-col>
      </el-row>
      <el-row class="row_login_password">
        <el-col :span="20" :offset="2">
          <el-input class="input_login_password"
                    v-model="password" type="password"
                    @input="change"
                    placeholder="用户密码"
                    show-password />
        </el-col>
      </el-row>
    </div>
    <div class="box_login_check">
      <el-button class="btn_login_check"
                 @click="tryLogin"
                 type="primary"
                 size="large"
                 :icon="Check"
                 :loading="isLoading"
                 :disabled="isDisable"
                 circle />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { myAlert } from '@/util/msg'
import { Check } from '@element-plus/icons-vue'

export default {
  name: "LoginBox",
  data() {
    return {
      uid: '',
      // 输入的账号
      account: '',
      // 输入的密码
      password: '',
      // 按钮是否是加载状态
      isLoading: false,
      // 按钮是否不可用
      isDisable: true,
      // check icon
      Check,
      logo: { url: require("@/assets/zqhome.png") }
    }
  },
  methods: {
    /**
     * 尝试登录方法
     */
    tryLogin() {
      // 封装账号密码，登录方式为密码登录
      let data = {
        'loginType': 200,
        'userInfo': {
          'userAccount': this.account,
          'userPassword': this.password
        }
      }

      // 发送 post 请求，url：'/zqhome/tryLogin'
      axios({
        url: '/zqhome/tryLogin',
        method: 'POST',
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否登录成功
        if (result.Code === 212) {
          this.uid = result.Data.uid
          // 跳转至主页
          this.handle()
          myAlert(this.$message, result.Message, 'success')
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    },
    /**
     * 页面跳转方法
     */
    handle() {
      // 跳转到 '/home' 页面
      this.$router.push({
        path: '/home/homepage',
        query: {
          uid: this.uid
        }
      })
    },
    /**
     * 设置登录按钮可用性,监听输入框是否输入
     */
    change() {
      this.isDisable = !(this.account !== '' && this.password !== '');
    }
  }
}

</script>

<style scoped>

.wrapper_login_loginbox {
  height: 500px;
  width: 440px;
  display: flex;
}

.box_login_space {
  height: 500px;
  width: 400px;
  border-radius: 20px;
  background: white;
}

.box_login_check {
  position: absolute;
  margin-left: 360px;
  margin-top: 350px;
}

.row_login_header {
  margin-top: 50px;
}

.row_login_tip {
  margin-top: 30px;
}

.row_login_account {
  margin-top: 30px;
}

.row_login_password {
  margin-top: 15px;
}

.text_login_head {
  font-size: 25px;
  color: cornflowerblue;
  font-family: '黑体';
}

.text_login_tip{
  font-size: 20px;
  font-family: '黑体';
}

.btn_login_check {
  min-width: 80px;
  min-height: 80px;
  font-size: 40px;
}

/*
.input_login_account >>> .el-input__wrapper {
  //box-shadow: 0 0 0 0;
}
 */

/*
.input_login_password >>> .el-input__inner {
  font-size: 20px;
}
 */

/*
.input_login_account {
  width: 100%;
  outline: none;
  padding-bottom: 10px;
  font-size: 15px;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 1px;
}
 */

</style>
