<template>
  <el-container class="container_device_manage">
    <el-header class="header_device_manage">
      <div class="wrapper_device_manage_header_head">
        <span class="text_device_manage_header_head">设备管理</span>
      </div>
      <div class="wrapper_device_manage_header_comps">
        <el-select v-model="value" class="select_device_manage_type" placeholder="设备类型" >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"/>
        </el-select>
        <el-button class="btn_device_manage_refresh"
                   type="primary"
                   @click="refresh">刷新</el-button>
      </div>
    </el-header>
    <el-main class="main_device_manage">
      <div class="wrapper_device_manage_main">
        <div class="wrapper_device_manage_main_head">
          <span class="text_device_manage_main_head">设备列表</span>
          <el-button class="btn_device_manage_add" :icon="Plus" @click="addDevice">添加设备</el-button>
        </div>
        <div class="wrapper_device_manage_main_table">
          <el-table :data="tempData" stripe style="height: 100%;width: 100% ">
            <el-table-column prop="devID" label="设备ID"/>
            <el-table-column prop="devName" label="名称"/>
            <el-table-column
                prop="devType" label="设备类型" sortable  column-key="date"
                :filters="[
                    { text: '光照传感器', value: '光照传感器' },
                    { text: '湿度传感器', value: '湿度传感器' },
                    { text: '温度传感器',value:'温度传感器'},
                    { text: '路由器',value:'路由器'},
                    { text: '服务器',value:'服务器'},
                    { text: 'LED', value: 'LED' },
                ]"
                :filter-method="filterHandler"
            />
            <el-table-column prop="devBrand" label="品牌" />
            <el-table-column prop="devModel" label="型号" />
            <el-table-column prop="devLocation" label="所处空间" />
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                    size="small"
                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button
                    size="small"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-main>

    <el-dialog
        v-model="insertDialogVisible"
        title="新增设备"
        width="30%"
    >
      <template #default>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入设备名称</span>
            <el-input v-model="device_name_add"/>
          </el-col>
          <el-col :span="12">
            <span>请输入设备类型</span>
            <el-input v-model="device_type_add"/>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入设备品牌</span>
            <el-input v-model="device_brand_add"/>
          </el-col>
          <el-col :span="12">
            <span>请输入设备型号</span>
            <el-input v-model="device_model_add"/>
          </el-col>
        </el-row >
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入设备所处空间</span>
            <el-input v-model="device_location_add"/>
          </el-col>
        </el-row>
        <el-row justify="center" style="margin-top: 60px">
          <el-button type="primary" @click="add_confirm">确定</el-button>
          <el-button @click="add_cancel">取消</el-button>
        </el-row>
      </template>
    </el-dialog>
    <el-dialog
        v-model="updateDialogVisible"
        title="修改设备"
        width="30%"
    >
      <template #default>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入设备名称</span>
            <el-input v-model="device_name_edit"/>
          </el-col>
          <el-col :span="12">
            <span>请输入设备类型</span>
            <el-input v-model="device_type_edit"/>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入设备品牌</span>
            <el-input v-model="device_brand_edit"/>
          </el-col>
          <el-col :span="12">
            <span>请输入设备型号</span>
            <el-input v-model="device_model_edit"/>
          </el-col>
        </el-row >
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="12">
            <span>请输入设备所处空间</span>
            <el-input v-model="device_location_edit"/>
          </el-col>
        </el-row>
        <el-row justify="center" style="margin-top: 60px">
          <el-button type="primary" @click="edit_confirm">确定</el-button>
          <el-button @click="edit_cancel">取消</el-button>
        </el-row>
      </template>

    </el-dialog>
  </el-container>

</template>

<script >
import { Plus } from "@element-plus/icons-vue";
import axios from "axios";
import { myAlert } from '@/util/msg'


export default {
  name: 'DeviceContent',
  data() {
    return {
      insertDialogVisible: false,
      updateDialogVisible: false,
      Plus,
      value: '',
      device_name_add: '',
      device_type_add: '',
      device_brand_add: '',
      device_model_add:'',
      device_location_add:'',
      tableData:'',
      device_name_edit: '',
      device_type_edit: '',
      device_brand_edit: '',
      device_model_edit:'',
      device_location_edit:'',
      devID:'',
      options,
      tempData:[],

    };

  },
  watch:{
    value: function(val) {
      if(val == ''){
        this.queryAllDev()
      }else{
        this.tempData=this.tableData.filter((item)=>{
          return item.devType.includes(val)
        })

      }
    },
  },


  methods: {



    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },

    queryAllDev(){
      axios({
        url: '/zqhome/queryDeviceAll',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 441) {
          this.tableData = result.Data
          this.tempData=this.tableData

        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })

    },

    /**
     * 编辑操作
     * @param index 行索引
     * @param row 行数据
     */
    handleEdit(index, row) {
      this.updateDialogVisible = true
      this.device_name_edit =  row.devName
      this.device_type_edit = row.devType
      this.device_brand_edit = row.devBrand
      this.device_model_edit = row.devModel
      this.device_location_edit = row.devLocation
      this.devID = row.devID
    },
    edit_confirm(){
      let data = {
        'devID': this.devID,
        'devName': this.device_name_edit,
        'devType': this.device_type_edit,
        'devBrand': this.device_brand_edit,
        'devModel':this.device_model_edit,
        'devLocation':this.device_location_edit
      }
      // 发送 post 请求，url：'/zqhome/updateDeviceInfo'
      axios({
        url: '/zqhome/updateDeviceInfo',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否修改成功
        if (result.Code === 451) {
          myAlert(this.$message, result.Message, 'success')
          this.refresh()
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
      this.updateDialogVisible = false

    },
    edit_cancel(){
      this.updateDialogVisible = false
    },

    /**
     * 删除操作
     * @param index 行索引
     * @param row 行数据
     */
    handleDelete(index, row) {
      console.log(index, row)
      let data = {
        'devID': row.devID
      }
      // 发送 post 请求，url：'/zqhome/deleteDevice'
      axios({
        url: '/zqhome/deleteDevice',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否删除成功
        if (result.Code === 445) {
          myAlert(this.$message, result.Message, 'success')
          this.refresh()
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    },

    refresh() {
      this.getDeviceAll()
    },

    add_confirm() {
      let data = {
        'devName': this.device_name_add,
        'devType': this.device_type_add,
        'devBrand': this.device_brand_add,
        'devModel':this.device_model_add,
        'devLocation':this.device_location_add
      }
      // 发送 post 请求，url：'/zqhome/insertDevice'
      axios({
        url: '/zqhome/insertDevice',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否添加成功
        if (result.Code === 448) {
          myAlert(this.$message, result.Message, 'success')
          this.refresh()
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
      this.insertDialogVisible = false
    },


    add_cancel() {
      this.insertDialogVisible = false
    },

    addDevice(){
      this.insertDialogVisible = true
    },

    getDeviceAll() {
      // 发送 post 请求，url：'/zqhome/queryDeviceAll'
      axios({
        url: '/zqhome/queryDeviceAll',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 441) {
          myAlert(this.$message, result.Message, 'success')
          this.tableData = result.Data
          this.tempData=this.tableData
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    },

  },

  mounted() {
    this.getDeviceAll()
  }
}

const options = [
  {
    value:'光照传感器',
    label:"光照传感器"
  },
  {
    value:'温度传感器',
    label:"温度传感器"
  },
  {
    value:'湿度传感器',
    label:"湿度传感器"
  },
  {
    value:'LED',
    label:'LED'
  },
  {
    value:'路由器',
    label:'路由器'
  },
  {
    value:'',
    label: "全部设备"
  },

]


</script>


<style scoped>

.container_device_manage {
  height: 100%;
}

.container_device_manage .header_device_manage {
  height: 150px;
}

.container_device_manage .main_device_manage {
  background-color: lightgrey;
}

.wrapper_device_manage_header_head {
  padding: 10px;
}

.wrapper_device_manage_header_comps {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.btn_device_manage_refresh {
  margin-right: 10px;
}

.text_device_manage_header_head {
  font-size: 30px;
}

.wrapper_device_manage_main {
  background-color: white;
  min-width: 100%;
  min-height: 100%;
  display: table;
}

.wrapper_device_manage_main_head {
  padding: 10px;
  margin-top: 10px;
  color: cornflowerblue;
  display: flex;
  justify-content: space-between;
}

.text_device_manage_main_head {
  font-size: 20px;
}

.btn_device_manage_add {
  margin-right: 10px;
}

.wrapper_device_manage_main_table {
  padding: 10px;
}

</style>
