<template>
  <!--  主页面渲染位置-->
  <router-view name="main_view"></router-view>
</template>

<script>
export default {
}
</script>
<script setup>
import router from '@/router'
// 跳转到登陆界面
router.push('/')
//'/home/device/terminal?uid=1002'
// /home/testMqtt
</script>
<style>
</style>
