<template>
  <div class="wrapper_main_view">
    <div class="login">
      <!-- 登录框 -->
      <LoginBox class="box_login"/>
    </div>
    <div class="wrapper_beian">
      <img src="@/assets/gov.png">
      <a class="link_beian" href="https://beian.miit.gov.cn">
        苏ICP备2022024990号-2
      </a>
    </div>
  </div>
</template>

<script>
import LoginBox from "@/components/login/LoginBox";
export default {
  name: "Login",
  components: {LoginBox}
}
</script>

@/assets/stacked-waves-haikei.svg
<style>
.wrapper_main_view {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("@/assets/background.jpg");
  display: flex;
  align-items: center;
}

.login {
  margin-left: 65%;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
}
.wrapper_beian {
  position: absolute;
  top:95%;
  left:50%;
  transform: translate(-50%,-50%);
}
.link_beian {
  text-decoration: none;
  color: white;
}
</style>
