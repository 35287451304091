<template>
  <el-container class="container_user">
    <el-header class="header_user">
      <div class="wrapper_user_header_head">
        <span class="text_user_header_head">用户信息</span>
      </div>
      <div class="wrapper_user_header_comps">
        <div class="wrapper_user_header_btns">
          <el-button class="btn_user_edit"
                     :icon="Edit"
                     v-show="!isEdit"
                     type="primary"
                     @click="edit">修改</el-button>
          <el-button class="btn_user_save"
                     v-show="isEdit"
                     type="primary"
                     :icon="UploadFilled"
                     @click="save">保存</el-button>
        </div>
      </div>
    </el-header>
    <el-main class="main_user">
      <div class="wrapper_user_main">
        <div class="wrapper_user_main_head">
          <span class="text_user_main_head">基本信息</span>
        </div>
        <div class="wrapper_user_main_info">
          <el-row :gutter="40">
            <el-col :span="8">
              <div class="wrapper_user_main_info_id">
                <span>用户ID</span>
                <el-input
                    v-model="id"
                    disabled/>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="wrapper_user_main_info_name">
                <span>用户姓名</span>
                <el-input
                    v-model="name"
                    :disabled="isDisable" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="wrapper_user_main_info_telephone">
                <span>联系方式</span>
                <el-input v-model="telephone" :disabled="isDisable"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="8">
              <div class="wrapper_user_main_info_password">
                <span>密码</span>
                <el-input v-model="password" :disabled="isDisable"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="wrapper_user_main_info_sex">
                <span>性别</span><br/>
                <el-select v-model="sex" :disabled="isDisable">
                  <el-option
                      v-for="item in option_sex"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"/>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="wrapper_user_main_info_birthdate">
                <span>出生日期</span><br/>
                <el-date-picker v-model="birthdate" :disabled="isDisable"></el-date-picker>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="8">
              <div class="wrapper_user_main_info_password">
                <span>电子邮箱</span>
                <el-input v-model="email" :disabled="isDisable"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { Edit, UploadFilled } from '@element-plus/icons-vue'
import axios from "axios";
import { myAlert } from '@/util/msg'

export default {
  name: "UserContent",
  data() {
    return {
      Edit,
      UploadFilled,
      isDisable: true,
      isEdit: false,
      id: '',
      name: '',
      telephone: '',
      password: '',
      sex: '',
      birthdate: '',
      email: '',
      option_sex
    }
  },
  methods: {
    /**
     * 编辑
     */
    edit() {
      this.isEdit = true
      this.isDisable = false
    },
    /**
     * 保存
     */
    save() {
      this.isEdit = false
      this.isDisable = true

      let data = {
        'uid': this.id,
        'userName': this.name,
        'userTel': this.telephone,
        'userPassword': this.password,
        'userGender': this.sex,
        'userBirthday': this.birthdate,
        'userEmail': this.email
      }

      // 发送 post 请求，url：'/zqhome/updateUserInfo'
      axios({
        url: '/zqhome/updateUserInfo',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'},
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 411) {
          myAlert(this.$message, result.Message, 'success')
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })

    },
    /**
     * 获取用户信息方法
     */
    getUserInfo() {

      let data = {
        'uid': this.id,
      }

      // 发送 post 请求，url：'/zqhome/queryUserInfo'
      axios({
        url: '/zqhome/queryUserInfo',
        method: 'POST',
        data: data
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 401) {
          this.name = result.Data.userName
          this.telephone = result.Data.userTel
          this.password = result.Data.userPassword
          this.sex = result.Data.userGender
          this.birthdate = result.Data.userBirthday
          this.email = result.Data.userEmail
          myAlert(this.$message, result.Message, 'success')
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    }
  },
  props: [
    'uid'
  ],
  mounted() {
    this.id = this.$route.query.uid
    this.getUserInfo()
  }
}

const option_sex = [
  {
    value: "男",
    label:"男"
  },
  {
    value: "女",
    label:"女"
  }
]

</script>

<style scoped>

.container_user {
  height: 100%;
}

.container_user .header_user {
  height: 150px;
}

.container_user .main_user {
  background-color: lightgrey;
}

.wrapper_user_header_head {
  padding: 10px;
}

.wrapper_user_header_comps {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: right;
}

.text_user_header_head {
  font-size: 30px;
}

.wrapper_user_header_btns {
  margin-right: 10px;
}

.wrapper_user_main {
  background-color: white;
  min-height: 100%;
  min-width: 100%;
  display: table;
}

.wrapper_user_main_head {
  padding: 40px;

  display: flex;
  justify-content: left;
}

.wrapper_user_main_info {
  padding: 40px;
}

.text_user_main_head {
  font-size: 20px;
  color: cornflowerblue;
}

.el-row {
  min-width: 100%;
  margin-bottom: 20px;
}

</style>