<template>
  <el-container class="container_gateway">
    <el-header class="header_gateway">
      <div class="wrapper_gateway_header_head">
        <span class="text_gateway_header_head">网关管理</span>
      </div>
      <div class="wrapper_gateway_header_comps">
        <el-select v-model="value" class="select_gateway_type" placeholder="网关类型">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"/>
        </el-select>
        <el-button class="btn_gateway_refresh"
                   type="primary">刷新</el-button>
      </div>
    </el-header>
    <el-main class="main_gateway">
      <div class="wrapper_gateway_main">
        <div class="wrapper_gateway_main_head">
          <span class="text_gateway_main_head">网关列表</span>
          <el-button class="btn_gateway_add" :icon="Plus">添加网关</el-button>
        </div>
        <div class="wrapper_gateway_main_table">
          <el-table :data="tableData" stripe>
            <el-table-column prop="gatewayID" label="网关ID"/>
            <el-table-column prop="gatewayName" label="网关名称"/>
            <el-table-column prop="gatewayConf" label="房间"/>
            <el-table-column prop="devLocation" label="设备位置"/>
            <el-table-column prop="roomID" label="房间ID"/>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                    size="small"
                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button
                    size="small"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-main>
  </el-container>

</template>

<script>
import { Plus } from "@element-plus/icons-vue";
import axios from "axios";
import { myAlert } from '@/util/msg'


export default {
  name: 'GateWayContent',
  data() {
    return {
      Plus,
      value: '',
      options,
      tableData:''
    }
  },
  mounted() {
    this.getGatewayAll()
    },
  methods: {
    getGatewayAll(){
      // 发送 post 请求，url：'/zqhome/queryGatewayAll'
      axios({
        url: '/zqhome/queryGatewayAll',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 461) {
          myAlert(this.$message, result.Message, 'success')
          this.tableData = result.Data
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    },
    /**
     * 编辑操作
     * @param index 行索引
     * @param row 行数据
     */
    handleEdit(index, row) {
      console.log(index, row)
    },
    /**
     * 删除操作
     * @param index 行索引
     * @param row 行数据
     */
    handleDelete(index, row) {
      console.log(index, row)
    }
  }
}
const options = [
  {
    value:"类型1",
    label:"类型1"
  },
  {
    value:"类型2",
    label:"类型2"
  }
]

</script>

<style scoped>

.container_gateway {
  height: 100%;
}

.container_gateway .header_gateway {
  height: 150px;
}

.container_gateway .main_gateway {
  background-color: lightgrey;
}

.wrapper_gateway_header_head {
  padding: 10px;
}

.wrapper_gateway_header_comps {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.text_gateway_header_head {
  font-size: 30px;
}

.wrapper_gateway_main {
  background-color: white;
  min-height: 100%;
  min-width: 100%;
  display: table;
}

.wrapper_gateway_main_head {
  padding: 10px;
  margin-top: 10px;
  color: cornflowerblue;
  display: flex;
  justify-content: space-between;
}

.text_gateway_main_head {
  font-size: 20px;
}

.btn_gateway_refresh {
  margin-right: 10px;
}

.btn_gateway_add {
  margin-right: 10px;
}

.wrapper_gateway_main_table {
  padding: 10px;
}

</style>
