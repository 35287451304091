<template>
  <div class="wrapper_home_view">
    <el-row class="row_home">
      <el-col :span="3">
        <!--    左侧侧边栏渲染位置-->
        <router-view name="left_sidebar"></router-view>
      </el-col>
      <el-col :span="21">
        <!--    主内容渲染位置-->
        <router-view name="home_content"></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "homepage"
}
</script>

<style>

.wrapper_home_view {
  width: 100%;
  height: 100%;
  position: fixed;
}

.row_home {
  width: 100%;
  height: 100%;
}
</style>