
<template>
  <div >
    <el-row>
      <el-col span="4"  v-for="(item) in tempData"
              :key="item.devID" :offset="1">
        <div style="margin: 25px;">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>{{item.devName}}</span>
                <el-button @click="info">查看详细</el-button>
              </div>
            </template>
            <div style="height: 200px">
              <div style="margin:3px"><span>设备类型:{{item.devType}}</span><br></div>
              <div style="margin:3px"><span>设备品牌:{{item.devBrand}}</span><br></div>
              <div style="margin:3px"><span>设备型号:{{item.devModel}}</span><br></div>
              <div style="margin:3px"><span>所处位置:{{item.devLocation}}</span></div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
  <el-dialog v-model="infoDialog">
    <div>

    </div>
  </el-dialog>
</template>
<script >
import mqtt from "mqtt/dist/mqtt.min"
import axios from "axios";
import { myAlert } from '@/util/msg'

export default {
  name:'HomePage',
  data(){
    return{
      tempData:'',
      infoDialog:false,
      client: "",
      message: "",
      logs: [],
    }
  },
  mounted() {
    this.getDeviceAll()
  },
  methods: {
    getDeviceAll() {
      // 发送 post 请求，url：'/zqhome/queryDeviceAll'
      axios({
        url: '/zqhome/queryDeviceAll',
        method: 'POST',
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      }).then(response => { // 请求成功
        // 获取数据
        let result = response.data
        // 打印返回信息
        console.log(result)
        // 判断是否查询成功
        if (result.Code === 441) {
          myAlert(this.$message, result.Message, 'success')
          this.tableData = result.Data
          this.tempData=this.tableData
        } else {
          myAlert(this.$message, result.Message, 'error')
        }
      }, error => { // 请求失败
        console.log(error)
        myAlert(this.$message, error.message, 'error')
      })
    },
    info(){
      this.infoDialog=true
      this.connectMqtt()
    },
    /**
     * @name:初始化mqtt
     * @msg:
     * @param {*}
     * @return {*}
     */
    connectMqtt () {
      const options={
        clean: true, // 保留会话
        connectTimeout: 4000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间
        clientId:'clientId-5043879312294918',//唯一值
        port:8083,//端口
        host:'server.zhangqihao.cn',//地址
        username:'Android5043879312294918',//id
        password:'eyJhbGciOiJIUzI1NiJ9.eyJ3NX0.hd-DD1_wCyQVcLTP_R4PqMYygWlebHjFQNTnSjeUuoo',//密码
      }
      this.client=mqtt.connect('ws://server.zhangqihao.cn:8083/mqtt',options);
      this.client.on('connect',e=>{
        console.log('服务器链接成功');
        this.client.subscribe('/room/comment/${activityCode}',{qos:0},err=>{
          debugger
          if(!err){
            console.log('订阅成功');
          }else{
            console.log('订阅失败');
          }
        })
      })
      //信息监听事件
      this.client.on('message',(topic,message)=>{
        console.log('收到'+message.toString());
      })
      //重连
      this.client.on('reconnect',(err)=>{
        console.log('正在进行重连',err);
      })
      //失败
      this.client.on('error',(err)=>{
        console.log('连接失败',err);
      })
    },

  },
}
</script>


<style >

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-card {
  width: 250px;
}


</style>
