<template>
  <div style="padding: 20px">
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="12">
        <span>请输入房间ID</span>
        <el-input v-model="input"/>
      </el-col>
      <el-col :span="12">
        <span>请输入房间名称</span>
        <el-input v-model="input"/>
      </el-col>
     </el-row>
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="12">
       <span>请输入所在楼层</span>
       <el-input v-model="input"/>
      </el-col>
      <el-col :span="12">
       <span>请输入占地面积㎡</span>
       <el-input v-model="input"/>
      </el-col>
    </el-row >
    <el-row justify="center" style="margin-top: 10px">
      <el-button type="primary">确定</el-button>
      <el-button >取消</el-button>
    </el-row>
  </div>

</template>

<script  setup>
import { ref } from 'vue'
const input = ref('')
</script>

<style scoped>

</style>
