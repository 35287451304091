<template>

</template>

<script>

export default {
  name: "msg"
}

/**
 * 导出 alert 方法
 * @param message this.$message
 * @param msg 打印信息
 * @param type 打印类型
 */
export function myAlert(message, msg, type) {
  message({
    showClose: true,
    message: msg,
    type: type
  })
}
</script>

<style scoped>

</style>