<template>
  <div class="wrapper_home_left_sidebar">
    <el-menu
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        class="el-menu-vertical"
        @close="handleClose"
        @open="handleOpen"
        @select="handSelect">
      <el-menu-item index="1">
        <el-icon><HomeFilled /></el-icon>
        <span>主页</span>
      </el-menu-item>
      <el-sub-menu index="2">
        <template #title>
          <el-icon><VideoCamera/></el-icon>
          <span>设备管理</span>
        </template>
        <el-menu-item-group title="设备类型">
          <el-menu-item index="2-1">终端设备</el-menu-item>
          <el-menu-item index="2-2">网关设备</el-menu-item>
        </el-menu-item-group>
      </el-sub-menu>
      <el-menu-item index="3">
        <el-icon><Notebook/></el-icon>
        <span>日志</span>
      </el-menu-item>
      <el-menu-item index="4">
        <el-icon><House/></el-icon>
        <span>家庭管理</span>
      </el-menu-item>
      <el-menu-item index="5">
        <el-icon><Avatar/></el-icon>
        <span>用户</span>
      </el-menu-item>
      <el-menu-item index="6">
        <span>MQTT客户端</span>
      </el-menu-item>
      <el-menu-item index="7">
        <span>设备卡片页面</span>
      </el-menu-item>

    </el-menu>
  </div>
</template>

<script>
import {
  Notebook,
  House,
  VideoCamera,
  Avatar, HomeFilled
} from '@element-plus/icons-vue'

 export default {
   name: "LeftSideBar",
   components: {
     VideoCamera,
     Notebook,
     Avatar,
     House,
     HomeFilled,
   },
   methods: {
     /**
      * 侧边栏打开
      * @param key
      * @param keyPath
      */
     handleOpen(key, keyPath) {
       console.log("打开：",key, keyPath);
     },
     /**
      * 侧边栏关闭
      * @param key
      * @param keyPath
      */
     handleClose(key, keyPath) {
       console.log("关闭：",key, keyPath);
     },
     /**
      * 侧边栏选中
      * @param key
      * @param keyPath
      */
     handSelect(key, keyPath) {
       console.log("选择：",key, keyPath);
       // 侧边栏选中跳转
       switch (key) {
         case '1':
           this.$router.push({
             path: '/home/HomePage',
             query: { uid: this.$route.query.uid }
           }); break
         case '2-1':
           this.$router.push({
             path: '/home/device/terminal',
             query: { uid: this.$route.query.uid }
           }); break
         case '2-2':
           this.$router.push({
             path: '/home/device/gateway',
             query: { uid: this.$route.query.uid }
           }); break
         case '3':
           this.$router.push({
             path: '/home/log',
             query: { uid: this.$route.query.uid }
           }); break
         case '4':
           this.$router.push({
             path: '/home/family',
             query: { uid: this.$route.query.uid }
           }); break
         case '5':
           this.$router.push({
             path: '/home/user',
             query: { uid: this.$route.query.uid }
           }); break
         case '6':
           this.$router.push({
             path: '/home/testMqtt',
             query: { uid: this.$route.query.uid }
           }); break
         case '7':
           this.$router.push({
             path: '/home/DevCard',
             query: { uid: this.$route.query.uid }
           }); break
       }
     }
   }
 }

</script>

<style>

.wrapper_home_left_sidebar {
  height: 100%;
  width: 100%;
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 100%;
  height: 100%;
}
</style>
